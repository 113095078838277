(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name persons
   * @description
   *
   */
  angular
  .module('neo.home.persons', [
    'ui.router',
    'neo.home.persons.person',
    'neo.home.persons.newPerson'
  ]);
}());
